// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMsMynOgIhInfd3eqX3tRgBZOqIYD4T4Q",
  authDomain: "docu-locker.firebaseapp.com",
  projectId: "docu-locker",
  storageBucket: "docu-locker.appspot.com",
  messagingSenderId: "462355613967",
  appId: "1:462355613967:web:80a0e0383284954a53567a",
  measurementId: "G-QG4674SP1G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

window.analytics = {
  logEvent: function(eventName, params) { logEvent(analytics, eventName, params) }
};
